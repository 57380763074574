/***************************************************************************************
 * FileName      : PostComment.js
 * Description   : 소곤핏 소곤톡 글 PostComment 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2024.05.17
 * Modifide Date : 2024.06.18 : CHM : 댓글 유저 프로필 클릭이벤트 카운팅 GA태그 추가
 *               :
 * Reference     :
 ***************************************************************************************/
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeart,
	faCommentDots,
	faPen,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "react-query";

import { deleteReply } from "../../../api/services/post";

import { AiOutlineAlert } from "react-icons/ai";
import { GoTrash } from "react-icons/go";

import ReportModal from "./ReportModal";
import { FaEyeSlash } from "react-icons/fa";
import { adminHiddenReply } from "../../../api/services/post";
import Dropdown from "react-bootstrap/Dropdown";
import { MdMoreVert } from "react-icons/md";
import ReactGA from "react-ga4";

function PostComment({ comment, user, postId }) {
	const queryClient = useQueryClient();

	// 댓글 작성자와 현재 로그인한 유저가 같은지 확인
	const [isWriter, setIsWriter] = useState(comment?.is_written || false);


	// 신고 모달 상태
	const [showModal, setShowModal] = useState(false);

	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	// 본인이 해당 댓글 삭제
	const handleDeleteClick = (Id) => {
		const confirmDelete = window.confirm("정말로 삭제 하시겠습니까?");
		if (confirmDelete) {
			deleteReply(Id)
				.then((response) => {
					queryClient.invalidateQueries(["replyList", postId]);
				})
				.catch((error) => {
					alert(error.response.data.error);
				})
				.finally(() => {});
		}
	};

	// 관리자 권한 해당 댓글 비공개 처리
	const handleAdminHiddenPost = (Id) => {
		const confirmDelete = window.confirm("정말로 비공개 하시겠습니까?");
		if (confirmDelete) {
			adminHiddenReply(Id)
				.then((response) => {
					alert("해당 댓글이 비공개 되었습니다.");
					queryClient.invalidateQueries(["replyList", postId]);
				})
				.catch((error) => {
					console.error("삭제 중 에러 발생:", error);
				})
				.finally(() => {});
		}
	};

	return (
		<>
			<div className="post-comment">
				<div className="comment-wrap">
					<div className="comment-user">
						{comment?.user?.profile ? (
							<img
								src={comment.user.profile}
								alt="User Profile"
								onClick={() => {
									ReactGA.event({
										action: "유저 프로필 클릭",
										category:
											"[소곤톡] 작성자 프로필 사진 클릭",
									});
								}}
								onContextMenu={(e) => e.preventDefault()}
							/>
						) : (
							<img
								src={
									process.env.PUBLIC_URL +
									"/favicon-96x96.png"
								}
								alt="Default Profile"
								onClick={() => {
									ReactGA.event({
										action: "유저 프로필 클릭",
										category:
											"[소곤톡] 작성자 프로필 사진 클릭",
									});
								}}
								onContextMenu={(e) => e.preventDefault()}
							/>
						)}
					</div>

					<div className="comment-content">
						<div className="comment-info">
							<div className="comment-user">
								<span
									className="nickname"
									onClick={() => {
										ReactGA.event({
											action: "유저 프로필 클릭",
											category:
												"[소곤톡] 작성자 닉네임 클릭",
										});
									}}
								>
									{/* {comment?.user?.nickname || comment?.user?.username} */}
									{comment?.user?.username == "Anonymous"
										? "익명의 소곤님"
										: comment?.user?.nickname}
								</span>
								<span className="date">
									{comment?.posted_at}
								</span>
							</div>
							<div className="comment-control">
								{user?.type === "A" ? (
									<>
										<Dropdown>
											<Dropdown.Toggle id="sort-dropdown">
												<MdMoreVert />
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() =>
														handleAdminHiddenPost(
															comment?.id
														)
													}
												>
													비공개
												</Dropdown.Item>
												{isWriter && (
													<Dropdown.Item
														onClick={() =>
															handleDeleteClick(
																comment?.id
															)
														}
													>
														삭제
													</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</>
								) : isWriter ? (
									<button
										className="more-button"
										onClick={() =>
											handleDeleteClick(comment?.id)
										}
									>
										<GoTrash
											style={{
												color: "#bfbfbf",
												fontSize: "18px",
											}}
										/>
									</button>
								) : (
									<button
										className="more-button"
										onClick={handleShowModal}
									>
										<AiOutlineAlert
											style={{
												color: "#bfbfbf",
												fontSize: "20px",
											}}
										/>
									</button>
								)}
							</div>
						</div>
						<div
							className="comment-container"
							style={{
								wordBreak: "break-word",
								whiteSpace: "pre-wrap",
							}}
						>
							<span>{comment?.content}</span>
						</div>
					</div>
				</div>
			</div>

			{/* 신고  모달 */}
			<ReportModal
				show={showModal}
				onHide={handleCloseModal}
				postId={comment?.id}
				reportType={"reply"}
			/>
		</>
	);
}

export default PostComment;
