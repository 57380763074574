/***************************************************************************************
 * FileName      : SimpleTestResult.js
 * Description   : 소곤핏고객 분석 결과 페이지
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  : 2024.06.25
 * Modifide Date :
 *               :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import MobileHeader from "../Component/MobileHeader";
import Gnb from "../../../Gnb";
import ReactGA from "react-ga4";
import { useRemoteConfig } from "@hackler/react-sdk";

import { getMe } from "../../../api/common/memberApi";
import { bbtiList } from "../../../api/services/bodytypeApi";
import { getBbtiMagazie } from "../../../api/services/magazine";

import { useNavigate } from "react-router-dom/dist";

function SimpleTest({}) {
	const [userData, setUserData] = useState(); // 사용자 데이터
	const [bbtis, setBbtis] = useState(); // 체형 리스트
	const [userBodyType, setUserBodyType] = useState(""); // 사용자 체형(bbti) 정보
	const [typeLoading, setTypeLoading] = useState(true); // Loading state
	const [showSpinner, setShowSpinner] = useState(true); // 3초 동안 스피너 표시

	const remoteConfig = useRemoteConfig(); // 핵클 원격구성 사용
	const parameterValue = remoteConfig.get("bbti_result_magazine", "");

	const [magazineContent, setMagazineContent] = useState([]);
	// 페이지 로딩 시 3초 동안 스피너를 강제로 표시
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSpinner(false);
		}, 3000);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		getMe()
			.then((res) => {
				setUserData(res);
			})
			.catch((err) => {
				setUserData("guest");
			});
	}, []);
	useEffect(() => {
		bbtiList().then((res) => {
			setBbtis(res?.data?.data);
		});
	}, []);
	useEffect(() => {
		if (userData?.bbti && bbtis) {
			setTypeLoading(true);

			const matchedBbti = bbtis?.find(
				(bbti) => bbti?.id === userData?.bbti
			);
			if (matchedBbti) {
				setUserBodyType(matchedBbti?.type);
				getBbtiMagazie(matchedBbti?.type).then((res) =>
					setMagazineContent(res?.data?.data)
				);
			}
			setTypeLoading(false);
		}
	}, [userData, bbtis]);

	const bodyTypes = {
		UOW: {
			type: ["#벌어진가슴형", "#윗가슴빈약형", "#볼륨발달형"],
			point: [
				"가슴을 모아서 입어도 어차피 금방 들떠요",
				"셔츠의 어깨선이 뒤쪽으로 처질 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
			],
		},
		UOA: {
			type: ["#윗가슴빈약형", "#짝가슴형", "#볼륨발달형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"가슴을 모아서 입어도 어차피 금방 들떠요",
			],
		},
		UOC: {
			type: ["#처진가슴형", "#윗가슴빈약형", "#볼륨발달형"],
			point: [
				"가슴 아래쪽 부분이 몸과 닿아 땀이차요",
				"편한 컵 사이즈를 입으면 가슴 윗쪽이 들떠요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		URW: {
			type: ["#퍼진가슴형", "#윗가슴빈약형", "#벌어진가슴형"],
			point: [
				"가슴을 모아서 입어도 어차피 금방 들떠요",
				"실제보다 브라를 입으면 빈약해 보이는 편이에요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
			],
		},
		URA: {
			type: ["#윗가슴빈약형", "#짝가슴형", "#완만한볼륨형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"가슴을 모아서 입어도 어차피 금방 들떠요",
			],
		},
		URC: {
			type: ["#윗가슴빈약형", "#완만한볼륨형", "#처진가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"편한 컵 사이즈를 입으면 가슴 윗쪽이 들떠요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		UIW: {
			type: ["#윗가슴빈약형", "#작은가슴형", "#벌어진가슴형"],
			point: [
				"가슴을 모아서 입어도 어차피 금방 들떠요",
				"셔츠의 어깨선이 뒤쪽으로 처질 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
			],
		},
		UIA: {
			type: ["#윗가슴빈약형", "#짝가슴형", "#작은가슴형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"가슴을 모아서 입어도 어차피 금방 들떠요",
			],
		},
		UIC: {
			type: ["#윗가슴빈약형", "#처진가슴형", "#작은가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"편한 컵 사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		MOW: {
			type: ["#뾰족한가슴형", "#볼륨발달형", "#벌어진가슴형"],
			point: [
				"상체가 너무 부해보이는게 고민이에요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"셔츠의 어깨선이 뒤쪽으로 처질 때가 많아요",
			],
		},
		MOA: {
			type: ["#뾰족한가슴형", "#짝가슴형", "#볼륨발달형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"브라에 따라 가슴만 뽀족한 느낌이 들 때가 있어요",
			],
		},
		MOC: {
			type: ["#뾰족한가슴형", "#볼륨발달형", "#처진가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"가슴 아래쪽 부분이 몸과 닿아 땀이 차요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		MRW: {
			type: [
				"#뾰족한가슴형",
				"#완만한볼륨형",
				"#퍼진가슴형",
				"#벌어진가슴",
			],
			point: [
				"가슴이 실제보다 브라를 입은 후에 더 작아보여요",
				"브라에 다라 가슴만 뾰족한 느낌이 들 때가 있어요",
				"사이즈는 맞는데 컵 안쪽이 꽉 차는 느낌이 안나요",
			],
		},
		MRA: {
			type: ["#뾰족한가슴형", "#완만한볼륨형", "#짝가슴형"],
			point: [
				"브라의 중심이 한쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"사이즈는 맞는데 컵 안쪽이 꽉 차는 느낌이 안나요",
			],
		},
		MRC: {
			type: ["#뾰족한가슴형", "#완만한볼륨형", "#처진가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"가슴 아래쪽 부분이 몸과 닿아 땀이 차요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		MIW: {
			type: ["#뾰족한가슴형", "#벌어진가슴형", "#작은가슴형"],
			point: [
				"가슴을 모아서 입어도 어차피 금방 들떠요",
				"와이어읜 끝이 가슴을 압박해서 답답해요",
				"사이즈는 맞는데 컵 안쪽이 꽉 차는 느낌이 안나요",
			],
		},
		MIA: {
			type: ["#뾰족한가슴형", "#짝가슴형", "#작은가슴형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"사이즈는 맞는데 컵 안쪽이 꽉 차는 느낌이 안나요",
			],
		},
		MIC: {
			type: ["#뾰족한가슴형", "#처진가슴형", "#작은가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"편한 정도의 컵 사이즈를 입으면 윗쪽이 비어요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		TOW: {
			type: [
				"#윗가슴발달형",
				"#벌어진가슴형",
				"#볼륨발달형",
				"#둥근가슴형",
			],
			point: [
				"상체가 너무 부해보이는게 고민이에요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"와이어가 빠지거나 부러져서 버리는 편이에요",
			],
		},
		TOA: {
			type: ["#윗가슴발달형", "#짝가슴형", "#볼륨발달형", "#둥근가슴형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"상체가 너무 부해보이는게 고민이에요",
				"와이어가 빠지거나 부러져서 버리는 편이에요",
			],
		},
		TOC: {
			type: [
				"#윗가슴발달형",
				"#처진가슴형",
				"#볼륨발달형",
				"#둥근가슴형",
			],
			point: [
				"상체가 너무 부해보이는게 고민이에요",
				"가슴 밑라인 부분이 몸과 닿아 땀이 차요",
				"와이어가 빠지거나 부러져서 버리는 편이에요",
			],
		},
		TRW: {
			type: [
				"#둥근가슴형",
				"#벌어진가슴형",
				"#완만한볼륨형",
				"#퍼진가슴형",
			],
			point: [
				"가슴을 모아서 입어도 어차피 금방 퍼져요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"브라의 와이어 앞쪽이 들떠서 불편해요",
			],
		},
		TRA: {
			type: ["#둥근가슴형", "#짝가슴형", "#완만한볼륨형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 압박해서 답답해요",
				"와이어가 빠지거나 부러져서 버리는 편이에요",
			],
		},
		TRC: {
			type: ["#둥근가슴형", "#처진가슴형", "#완만한볼륨형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"가슴 밑라인 부분이 몸과 닿아 땀이 차요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
		TIW: {
			type: ["#퍼진가슴형", "#작은가슴형", "#벌어진가슴형"],
			point: [
				"가슴을 모아서 입어도 어차피 금방 들떠요",
				"와이어의 끝이 가슴을 찌르는 느낌이라 답답해요",
				"컵의 아래쪽인 앞둘레 밴드가 위로 따라 올라가요",
			],
		},
		TIA: {
			type: ["#짝가슴형", "#작은가슴형", "#완만한가슴형"],
			point: [
				"브라의 중심이 한 쪽으로 쏠려있을 때가 많아요",
				"와이어의 끝이 가슴을 찌르는 느낌이라 답답해요",
				"컵의 아래쪽인 앞둘레 밴드가 위로 따라 올라가요",
			],
		},
		TIC: {
			type: ["#처진가슴형", "#작은가슴형", "#완만한가슴형"],
			point: [
				"유두의 위치가 팔꿈치와 5cm 내외로 가까워요",
				"와이어의 끝이 몸에 딱 닿아서 갑갑해요",
				"딱 맞는 컵사이즈를 입으면 앞중심 사이로 가슴이 쏠리거나 빠져나오려고 해요",
			],
		},
	};
	return (
		<>
			<Helmet>
				<title>소곤핏 sogonfit - 분석(결과)</title>
			</Helmet>
			<MobileHeader back={true} title={"검사결과"} />
			<div
				className="bbtitest"
				style={{ margin: "48px auto", paddingBottom: "64px", background: "#f7f3e4" }}
			>
				{showSpinner ? (
					<ResultSpinner />
				) : (
					<div className="inner">
						<p className="text-center fs-4 fw-bold my-4">
							{userData?.nickname}님은<br/>
							<span className="fc-main fw-bold">{userBodyType}</span> 체형이에요.
						</p>
						<div style={{
							borderRadius: "4px",
							background: "#fff",
							boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.1)",
							padding: "16px 8px"
						}}>
							<div style={{
								display: "flex",
								justifyContent: "space-around",
							}}>
								<p className="fc-main">{bodyTypes[userBodyType]?.type[0]}</p>
								<p className="fc-main">{bodyTypes[userBodyType]?.type[1]}</p>
								<p className="fc-main">{bodyTypes[userBodyType]?.type[2]}</p>
							</div>
							<hr style={{
								color: "#e85f43",
								margin: "8px",
							}}/>
							<div style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
								<img src={`img/bbti/result/side/${userBodyType}.png`} className="w-50 p-3"/>
								<img src={`img/bbti/result/front/${userBodyType}.png`} className="w-50 p-3"/>
							</div>
							<hr style={{
								color: "#e85f43",
								margin: "8px",
							}}/>
							<div className="text-center my-5">
								<p className="fc-main fw-bold my-3">{userBodyType}는 이런 체형이에요.</p>
								<p className="fc-gray" style={{fontSize: "14px"}}>{bodyTypes[userBodyType]?.point[0]}</p>
								<p className="fc-gray" style={{fontSize: "14px"}}>{bodyTypes[userBodyType]?.point[1]}</p>
								<p className="fc-gray" style={{fontSize: "14px"}}>{bodyTypes[userBodyType]?.point[2]}</p>
							</div>
							<hr style={{
								color: "#e85f43",
								margin: "8px",
							}}/>
							<div className="text-center my-5">
								<p className="fc-main fw-bold mt-3">{userData?.nickname}님을 위해 추천할게요.</p>
								<p className="fc-gray fw-bold mb-3" style={{fontSize: "12px"}}>분석 테스트를 모두 진행하면 정확도를 높일 수 있어요!</p>
								<img src={`img/bbti/result/asset_130.png`} className="w-100 p-3"/>
							</div>
							<hr style={{
								color: "#e85f43",
								margin: "8px",
							}}/>
							<div className="p-3">
								<p className="text-start fw-bold">
									도움이 될 만한 콘텐츠에요.
								</p>
								{magazineContent?.length > 0
									? magazineContent.map(
											(item, index) =>
												index <
													JSON.parse(parameterValue)
														?.maxContent && (
													<MagazineBlock
														item={item}
														key={item?.id}
													/>
												)
										)
									: "매거진이 없습니다."}
							</div>
						</div>
						
					</div>
				)}
				<Gnb />
			</div>
		</>
	);
}

function ResultSpinner() {
	return (
		<div className="text-center" style={{ padding: "45vh 0"}}>
			<div class="spin-body">
				<div class="spin-body__dot"></div>
				<div class="spin-body__dot"></div>
				<div class="spin-body__dot"></div>
			</div>
			<p>잠시만 기다려주세요.</p>
		</div>
	);
}

function MagazineBlock({ item }) {
	const navigate = useNavigate();
	return (
		<>
			<div
				className="my-magazine-content"
				style={{boxShadow: "none"}}
				onClick={() => {
					window.hackleClient.track({
						key: "clicked_sogonlog",
						properties: {
							클릭위치: "체형분석 결과페이지",
							타이틀: item?.title,
							글작성자: item?.user?.username,
							카테고리: item?.category?.name,
							레이아웃: "요약리스트형",
						},
					});
					ReactGA.event({
						category: "체형분석 결과페이지에서 클릭한 매거진",
						action: "Magazine Clicked",
						value: item?.id,
					});
					navigate(`/magazine/${item?.id}`);
				}}
			>
				<div className="my-m-img">
					<img src={item?.main_image} alt={item?.title} />
				</div>

				<div className="magazine-info">
					<div className="post-tit">
						<p className="fw-bold pe-3">{item?.title}</p>
					</div>
					<div className="post-tags">
						<span
							key={item?.category?.id}
							className="post-tag"
							style={{ padding: "4px 6px 4px 0px" }}
						>
							{item?.category?.name}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default SimpleTest;
