/***************************************************************************************
 * FileName      : Setting.js
 * Description   : 소곤핏 마이페이지 > 설정 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.08.26
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader.js";
import Gnb from "../../Gnb.js";
import { useForm } from "react-hook-form";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner.js";


import { getMe } from "../../api/common/memberApi.js";
import { FiPlus } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function Setting() {
	const navigate = useNavigate();

	// 캐시 데이터 삭제처리
	const clearCacheData = () => {
		if (window.confirm("캐시 데이터를 지우면 로그아웃됩니다. 계속하시겠습니까?")) {
			localStorage.clear();
			sessionStorage.clear();
			alert("캐시 데이터가 삭제되었습니다.");
		}
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 설정</title>
			</Helmet>
			<MobileHeader back={true} title={"설정"} />
			<div className="mypage">
				<section className="mypage-menu mt-5">
					<div className="inner">
						<ul className="ms-menu-wrap">
							<li className="pointer" onClick={() =>  alert("준비중입니다")}>알림 설정</li>
							<li className="pointer" onClick={() => clearCacheData()}>캐시 데이터 지우기</li>
							<li className="pointer" onClick={() => alert("마이페이지 > 고객센터 > 1:1 문의를 통해 관리자에게 문의 바랍니다.")}>서비스 탈퇴</li>
							<li>
								<div className="d-flex justify-content-between">
									<p onDoubleClick={() => navigate("/admin")}>버전 정보</p>
									<p>v0.2.14</p>
								</div>
							</li>

						</ul>
					</div>
				</section>
				<Gnb />
			</div>
		</>
	);
}

export default Setting;
